/* Overall Chatbot Styling */

  .message {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .message-content {
    padding: 10px 15px;
    border-radius: 10px;
    max-width: 70%;
    word-wrap: break-word;
    font-family: 'Google Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    line-height: normal;
    word-break: break-word;
  }
  
  .bot-message .message-content {
    background-color: #B3CEE5;
  }
  
  .user-message .message-content {
    background-color: #e9e9e9;
    color: #000;
    align-self: flex-end;
    font-family: Arial;
  }
  
  .message-meta-user {
    font-size: 12px;
    color: #888;
    /* margin-top: 5px; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .message-meta-bot {
    font-size: 12px;
    color: #888;
    /* margin-top: 5px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .favorite-icon {
    cursor: pointer;
  }
  
  .favorite {
    color: gold;
  }
  
  /* Input and Send Icon */
  .input-container {
    display: flex;
    padding: 10px;
    /* border-top: 1px solid #ccc; */
    /* background-color: #fff; */
    align-items: center;
  }
  
  input[type="text"] {
    flex-grow: 1;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    outline: none;
    font-size: 16px;
  }
  
  .send-icon {
    font-size: 24px;
    color: #007bff;
    margin-left: 10px;
    cursor: pointer;
  }
  
  /* Button to Toggle Chat Visibility */
  .toggle-chat-button {
    margin-bottom: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .toggle-chat-button:hover {
    background-color: #0056b3;
  }
  
  /* Chatbot Circle Button */
.chatbot-circle {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    /* background-color: #007bff; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  /* Chatbot Container */
  .chatbot-container {
    position: fixed;
    bottom: 50px;
    right: 20px;
    width: 530px;
    height: 630px;
    border: 1px solid #ccc;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .chatbot-container.open {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Chatbot Header */
  .chatbot-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: #1345ac;
    color: #fff;
  }
  
  .profile-info {
    display: flex;
    align-items: center;
  }
  
  .profile-photo {
    font-size: 30px;
    margin-right: 10px;
  }
  
  .title {
    font-size: 18px;
  }
  
  .minimize-icon, .clear-icon {
    font-size: 20px;
    cursor: pointer;
  }

  .chatbot-hover {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 40px;
  }
  
  /* Chatbox Styling */
  .chatbox {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
    background-color: #fff;
  }
  
  /* Other styles remain the same for messages, input box, etc. */
  .scroll-to-bottom {
    background-color: white; /* Optional: Background color for better visibility */
    border-radius: 50%; /* Optional: To make it circular */
    padding: 5px; /* Optional: Padding for aesthetics */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: Shadow for depth */
  }
  .profile-photo {
    width: 50px; /* Adjust as needed */
    height: 50px; /* Adjust as needed */
    border-radius: 50%; /* Circular shape */
    object-fit: cover; /* Ensures the image covers the space without distortion */
  }

  .message-thinking {
    font-size: 27px; /* Adjust size as needed */
    animation: blink 1s infinite; /* Apply blinking animation */
    color: #0056b3; /* Optional: change color */
  }
  
  @keyframes blink {
    0% {
      opacity: 1; /* Fully visible */
    }
    50% {
      opacity: 0; /* Invisible */
    }
    100% {
      opacity: 1; /* Back to fully visible */
    }
  }