df-messenger {
  z-index: 999;
  position: fixed;
  bottom: 8px;
  right: 16px;
--df-messenger-chat-window-height: 580px;  /* Reduce height (adjust as needed) */
--df-messenger-chat-window-height1: 700px;  /* Reduce height (adjust as needed) */
--df-messenger-chat-window-width: 500px;   /* Increase width (adjust as needed) */
--df-messenger-chat-window-width1: 650px;   /* Increase width (adjust as needed) */
--df-messenger-message-bot-background: #B3CEE5;
--df-messenger-chat-border-radius: 3px 3px;
--df-messenger-chat-border:none;
--df-messenger-chat-window-box-shadow:2px 2px 12px 5px #2904042A;
--df-messenger-chat-background: #FFFFFF;
--df-messenger-message-user-background: #e9e9e9;
--df-messenger-message-bot-writing-background: none;
--df-messenger-input-box-focus-border:none;
/*--df-messenger-input-box-focus-padding:14px;*/
/*--df-messenger-input-box-padding:14px;*/
--df-messenger-send-icon-color-active: #798eed;
--df-messenger-input-box-padding: 20px;
--df-messenger-titlebar-background: #04042A;
--df-messenger-titlebar-icon-padding:0px 12px 0px 0px;
--df-messenger-message-rich-feedback-background:#000000;  
--df-messenger-message-rich-feedback-border-radius:2px;
--df-messenger-message-rich-feedback-submit-font-color: #000000;
--df-messenger-icon-font-color: #000000;
--df-messenger-chips-background-hover:#99bbf1;
--df-messenger-message-rich-feedback-border-radius:2px;
--df-messenger-titlebar-title-align:start;
--df-messenger-titlebar-padding:15px 16px 15px 60px;
/* --df-messenger-titlebar-title-font-size:16px; */
--df-messenger-titlebar-title-font-size:18px;
--df-messenger-titlebar-title-font-weight:700;
--df-messenger-titlebar-background: #04042A 32px 20px / auto 40px no-repeat url('faceai.png');
--df-messenger-titlebar-icon-font-color:#ffffff;
--df-messenger-titlebar-font-color: #FFFFFF;
--df-messenger-chips-background-hover:#99bbf1;
--df-messenger-chat-bubble-background: center /  contain  url("https://routenotetest.com/rn22/use_luna_image/ai-light.svg");


/*hide old chat icon*/
--df-messenger-chat-bubble-icon-color:none;
--df-messenger-titlebar-icon: url("pngegg.png");
/*input text*/
--df-messenger-input-background: #ffffff;
--df-messenger-input-inner-padding:10px 12px;
--df-messenger-input-box-border-radius:none;
--df-messenger-input-box-background: #F5F5F5;
/*--df-messenger-input-box-padding:14px 10px;*/
--df-messenger-input-box-border:none;
--df-messenger-input-font-color:#000000;
/*--df-messenger-input-font-size:12px;*/
--df-messenger-input-font-weight:400;
--df-messenger-input-max-width:100%;
--df-messenger-input-padding:none;
--df-messenger-input-gutter:none;
/*feedback icon*/ 
--df-messenger-default-border: none !important;
--df-messenger-message-feedback-icon-border-radius: none !important;
--df-messenger-default-border-color: none !important;
--df-messenger-message-feedback-icon-border: 0px;
--df-messenger-message-feedback-icon-background-hover:none;
--df-messenger-message-feedback-icon-font-color-active:none;
--df-messenger-internal-message-feedback-icon-fill:#b879ed00;

/*close icon*/
--df-messenger-chat-bubble-close-icon:url("https://routenotetest.com/rn22/use_luna_image/close-luna.svg");
--df-messenger-chat-close-icon:url("https://routenotetest.com/rn22/use_luna_image/close-luna.svg");

/*scroll button*/
--df-messenger-chat-scroll-button-padding: 8px;
--df-messenger-chat-scroll-button-container-padding: 8px 16px;

/*input message border design*/

--df-messenger-message-internal-border-bottom-right-radius:none;
--df-messenger-message-user-border-top-right-radius:none;
--df-messenger-message-internal-border-top-right-radius:none;
--df-messenger-message-internal-border-top-left-radius:none;

--df-messenger-chips-border-color: #F5F5F5;


/*chips*/
--df-messenger-chips-border-radius: -10px;
--df-messenger-chips-border-color-hover: #FFFFFF;
/*--df-messenger-chips-font-size: 11px;*/
--df-messenger-message-feedback-icon-padding: none;
--df-messenger-message-bot-writing-padding:0px;


}


/*Tooltip message element css*/

.message-element {
  background-color: #1345AC;
  padding: 10px;
  position: absolute;
  bottom: 58px;
  right: 30px;
  width: 230px;
  height: 70px;
  border-radius: 2px;
  box-shadow: 0px 2px 12px rgba(4,4,42,0.08);
  transition: opacity 0.5s ease;
  margin-bottom: 30px;
}

.message-container {
  display: flex;
  align-items: center;
}

.message-icon {
  width: 11.22px;
  height: 13px;
  vertical-align: middle;
}

.message-text-primary {
  color: #FFFFFF;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
  line-height: 14px;
  font-weight: 700;
  padding-left: 5.78px;
}

.message-text-secondary {
  color: #FFFFFF;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  padding-left: 0px;
  padding-top: 5px;
}

.title-text h2{
  word-spacing: 5px;
}