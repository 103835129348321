/* DashboardCards.css */
.dashboard-card {
  margin-bottom: 24px !important;
}

.box {
	padding-left: 4% !important;
	padding-right: 3% !important;
}

.box1 {
	padding-left: 4% !important;
	padding-right: 3% !important;
}

.dashboard-card .MuiCardHeader-root {
  background-color: rgb(19, 69, 172) !important;
  color: white;
  text-align: center !important;
  border-bottom: 1px solid rgb(93, 99, 255);
}

.dashboard-card .MuiCardHeader-title {
  font-size: 22px !important;
  font-weight: bold !important;
  font-family: Arial, sans-serif !important;
}

.dashboard-card .MuiCardContent-root {
  background-size: cover !important;
  background-position: center !important;
  height: 540px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  padding: 50px !important;
  padding-left: 16% !important;
}

.dashboard-typography,
.dashboard-typography1 {
  font-family: 'Oswald' !important;
  color: white !important;
  font-size: 25px !important;
  font-weight: 100 !important;
}

.dashboard-typography {
  margin-left: 200px !important;
}

.dashboard-typography1 {
  line-height: 52px !important;
  margin-left: 140px !important;
}

.login-section {
  margin-top: auto !important;
  text-align: center !important;
}

.login-input {
  margin-bottom: 16px !important;
  background-color: white !important;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .css-mhc70k-MuiGrid-root > .MuiGrid-item {
    padding-left: 24px !important;
    padding-right: 24px !important;
    margin-left: 0 !important;
  }

  .dashboard-card .MuiCardContent-root {
    padding: 30px !important;
    padding-left: 10% !important;
  }

  .dashboard-typography,
  .dashboard-typography1 {
    margin-left: 0 !important;
    font-size: 22px !important;
  }
}

@media (max-width: 960px) {
  .dashboard-card .MuiCardContent-root {
    height: 450px !important;
  }

  .dashboard-typography,
  .dashboard-typography1 {
    font-size: 20px !important;
  }

  .dashboard-typography1 {
    line-height: 40px !important;
  }
}

@media (max-width: 600px) {
  .css-mhc70k-MuiGrid-root > .MuiGrid-item {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .dashboard-card .MuiCardContent-root {
    height: 400px !important;
    padding: 20px !important;
    padding-left: 5% !important;
  }

  .dashboard-typography,
  .dashboard-typography1 {
    font-size: 18px !important;
  }

  .dashboard-typography1 {
    line-height: 35px !important;
  }

  .dashboard-card .MuiCardHeader-title {
    font-size: 18px !important;
  }
}

@media (max-width: 480px) {
  .dashboard-card .MuiCardContent-root {
    height: 350px !important;
  }

  .dashboard-typography,
  .dashboard-typography1 {
    font-size: 16px !important;
  }

  .dashboard-typography1 {
    line-height: 30px !important;
  }
}